import {fetchData} from "../use-fetch.js"

export default {
	async loadUsers(context) {
		const responseData = await fetchData('v1/user');

		const users = [];

		if(responseData.users) {
			responseData.users.forEach(function (user) {
				const activeUser = {
					id: user.token,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					products: user.products,
				}
				users.push(activeUser);
			});
		}

		context.commit("setActiveUsers", users);
	},

	async loadEditForm(context, payload) {
		const userId = payload.userId;

		const responseData = await fetchData(`/v1/user/${userId}/edit`);

		const userEditForm = [];

		if(responseData.formStructure) {
			responseData.formStructure.forEach(function (form) {
				const editForm = {
					type: form.type,
					name: form.name,
					label: form.label,
					placeholder: form.placeholder,
					value: form.value,
					options: form.options,
				}
				userEditForm.push(editForm);
			});
		}

		context.commit("userEditForm", userEditForm);
		responseData.availability && context.commit('editUserAvailability', responseData.availability);
		responseData.license && context.commit("editUserLicense", responseData.license);
	},

	async saveEditForm(context, payload) {
		const userId = payload.userId;

		await fetchData(`/v1/user/${userId}`, {
			method: 'PUT',
			body: JSON.stringify(payload),
		});
	},

	async createUserForm(context) {
		const responseData = await fetchData('/v1/user/new');

		const createUserForm = [];

		if(responseData.formStructure) {
			responseData.formStructure.forEach(function (form) {
				const createForm = {
					type: form.type,
					name: form.name,
					label: form.label,
					placeholder: form.placeholder,
					value: form.value,
					options: form.options,
				}
				createUserForm.push(createForm);
			});
		}

		context.commit("createUserForm", createUserForm);
		responseData.availability && context.commit('userAvailability', responseData.availability);
	},

	async saveCreateForm(context, payload) {
		await fetchData('/v1/user', {
			method: 'POST',
			body: JSON.stringify(payload),
		});

	},

	async deleteUser(context, payload) {
		const userId = payload.userId;

		await fetchData(`/v1/user/${userId}`, {
			method: 'Delete',
		});
	},

	async loadProductOfUser(context, payload) {
		const userId = payload.userId;

		const responseData = await fetchData(`/v1/user/${userId}/product`);

		responseData.products && context.commit("setProductOfUser", responseData.products);
	},

	async addProductToUser(context, payload){
		const userId = payload.userId;

		await fetchData(`/v1/user/${userId}/product`, {
			method: 'POST',
			body: JSON.stringify(payload),
		});
	},

	async deleteProductOfUser(context, payload){
		const userId = payload.userId;
		const token = payload.productToken;

		await fetchData(`/v1/user/${userId}/product/${token}`, {
			method: 'Delete',
		});
	},

	async syncUsersGoogleCalendar(context, payload){
		const userId = payload.userId;

		await fetchData(`/v1/user/${userId}/syncCalendar`, {
			method: 'PUT',
		});
	},
}
