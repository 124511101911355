import mutations from './mutation.js';
import actions from './actions.js';
import getters from './getters';

export default {
	namespaced: true,
	state() {
		return{
			isLoggedIn: false,
			userToken: '',
			activeOrg: '',
			googleClientId: '',
			profilePhoto: '',
			appVersion: 'v1.0.1 11.11.GL',
			errorMessage: null,
			didAutoLogout: false,
			env: '',
		}
	},
	mutations,
	actions,
	getters,
}
