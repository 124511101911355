<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" class="base-modal" :style="{ height: height, width: width }">
      <header>
        <slot name="header">
          <h2>{{ title }}</h2>
        </slot>
      </header>
      <section>
        <slot></slot>
      </section>
      <menu v-if="!fixed">
        <slot name="actions">
<!--          <base-button mode="cancel" @click="tryClose">Cancel</base-button>-->
        </slot>
      </menu>
    </dialog>
  </teleport>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      required: false,
    },
    width: {
      required: false
    }
  },
  emits: ['close'],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 200vh;
  width: 200%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.base-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  background-color: white;
  font-size: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
}

header {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  font-size: 1em;
}

header h2 {
  margin: 0;
  font-size: 2em;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 0;
}

@media only screen and (min-width:250px) and (max-width: 599px){
  dialog {
    width: 100%;
    overflow: hidden;

  }
  header h2 {
    margin: 0;
    font-size: 1.6em;
  }
  .fixed{
    max-height: 80%;
    top:55%;
  }
  .big{
    left: calc(50%);
    width: 20rem;
    height: 15rem;
  }
  .top50, .addressModal{
    left: calc(50%);
    width: 20rem;
    top: 30%;
    height: 20rem
  }
}
@media only screen and (min-width:600px) and (max-width: 765px) {
  dialog {
    left: 10%;
    width: 80%;
  }
  .big {
    left: calc(50%);
    width: 30rem;
    height: 18rem
  }
  .top50, .addressModal {
    left: calc(50%);
    width: 30rem;
    height: 18rem;
    top: 30%
  }
}
@media only screen and (min-width:766px) and (max-width: 1099px)  {
  dialog {
    left: 10%;
    width: 80%;
  }
  .big {
    left: calc(50%);
    width: 30rem;
    top: 30%;
    height: 15rem;
  }
  .top50, .addressModal {
    left: calc(50%);
    width: 40rem;
    top: 35%;
    height: 25rem ;
  }
}
@media (min-width: 1100px) {
  dialog {
    left: 20%;
    width: 60%;
  }
}
#close-dialog{
  font-size: medium;
}
</style>