export default {
    setProductCategories(state, payload) {
        state.categories = payload;
    },
    setCreateCategoryForm(state, payload) {
        state.createCategoryForm = payload;
    },
    setEditCategoryForm(state, payload) {
        state.editCategoryForm = payload;
    }
}