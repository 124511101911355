import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions";
export default {
    namespaced: true,
    state() {
        return {
            products: [],
            availableTimes: [],
            selectedClinician: [],
            chosenDate: [],
            reCaptchaKey: [],
            addressToTimeZoneResponse: [],
            thirdPartyAvailableClinicians: [],
            scheduledClinician: [],
            scheduledAppointmentDetails: [],
            addAppointmentForm: [],
        }
    },
    mutations,
    actions,
    getters,
}