export default {
    setReports(state, payload) {
        state.reportsList = payload;
    },
    setClientSchedulerReportData(state, payload) {
        state.clientSchedulerReportData = payload;
    },
    setClickUpTaskUploadsReportData(state, payload) {
        state.clickUpTaskUploadsReportData = payload;
    },
    setClickUpTaskUploadEditForm(state, payload) {
        state.clickUpTaskUploadEditForm = payload;
    },
    setInternalSchedulerReportData(state, payload) {
        state.internalSchedulerReportData = payload;
    },
    setSetupErrorsData(state, payload) {
        state.setupErrorsData = payload;
    }
}