import {fetchData} from "../use-fetch.js";
import {addDays} from "@/components/ResusableFunctions/addDays";
import {reCaptchaKey} from "@/main";

export default {
    async loadEvents(context, payload) {
        let clinicianId = payload.clinicianId;
        let currentDate = payload.date;
        let timezone = payload.timezone;
        let startDate = addDays(currentDate, -8).toISOString().split('T')[0];
        let endDate = addDays(currentDate, 8).toISOString().split('T')[0];
        let productId = payload.productId;
        let state = payload.state;

        let path = `/v1/schedule/appointment?timezone=${timezone}&start=${startDate}&end=${endDate}&type=training&product=${productId}&state=${state}`;

        if(payload.clinicianId) {
            path = path.concat(`&clinician=${clinicianId}`);
        }

        let responseData = await fetchData(path);

        //getting available clinicians
        if (!responseData.availableClinicians || responseData.availableClinicians.length === 0) {
            context.commit("setAvailableClinicians", "No Clinician");
        } else {
            context.commit("setAvailableClinicians", responseData.availableClinicians);
        }

        // getting existing appointments
        const events = [];

        if(responseData.clinicianAppointments) {
            responseData.clinicianAppointments.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    summary: event.summary,
                    type: event.type,
                    presence: event.presence,
                    location: event.location,
                    notes: event.notes,
                    classNames: event.classNames,
                    editable: event.editable,
                    link: event.url,
                }
                events.push(eventsList);
            });
        }

        // pushing events restricted time to events array located in state.
        if(responseData.productRestrictedTime) {
            responseData.productRestrictedTime.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    editable: event.editable,
                    display: event.display,
                    classNames: event.classNames,
                }
                events.push(eventsList);
            });
        }

        //pushing clinician restricted time to events array located on state.
        if(responseData.clinicianRestrictedTime) {
            responseData.clinicianRestrictedTime.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    editable: event.editable,
                    display: event.display,
                    classNames: event.classNames,
                }
                events.push(eventsList);
            });
        }

        // getting clinician appointments
        if(responseData.appointments) {
            responseData.appointments.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    editable: event.editable,
                    display: event.display,
                    classNames: event.classNames,
                }
                events.push(eventsList);
            });
        }

        context.commit("setEvents", events);

        //getting selected clinician's name
        let selectedClinicianName = '';

        if(responseData.availableClinicians) {
            responseData.availableClinicians.forEach(function (user) {
                if (user.selected === true) {
                    selectedClinicianName = user.name;
                }
            });
            context.commit("setSelectedClinician", selectedClinicianName);
        }
    },

    async getAddEventForm(context, payload) {
        let productId = payload.productId;
        let state = payload.state;

        const responseData = await fetchData(`/v1/schedule/appointment/new?type=training&product=${productId}&state=${state}`);

        const createEventForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const createForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                }
                createEventForm.push(createForm);
            });
        }

        context.commit("setAddEventForm", createEventForm);
        responseData.script && context.commit("setProductScript", responseData.script);
    },

    async addEvents(context, payload){
        const responseData = await fetchData(`/v1/schedule/appointment`, {
        method: 'POST',
        body: JSON.stringify(payload),
        });

        if (!responseData.appointment || responseData.appointment.length === 0) {
            responseData.messages.forEach(function (message) {
                if(message.title === 'Scheduling Conflict') {
                    context.commit('setSchedulingConflict', "Conflict");
                }
            })
        } else {
            context.commit('setSchedulingConflict', '');
            context.commit('setCreatedApptData', responseData.appointment);
        }

    },

    async loadEditAppointmentForm(context, payload) {
        let timezone = payload.timezone;
        const appointmentId = payload.appointmentId

        const responseData = await fetchData(`/v1/schedule/appointment/${appointmentId}/edit?timezone=${timezone}`);

        const apptEditForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                }
                apptEditForm.push(editForm);
            });
        }

        context.commit("setEditAppointmentForm", apptEditForm);
        responseData.details && context.commit("setPatientInfo", responseData.details);
    },

    async editAppointment(context, payload) {
        let appointmentId = payload.appointmentId;

        await fetchData(`/v1/schedule/appointment/${appointmentId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
    },

    async deleteAppointment(context, payload) {
        const appointmentId = payload.appointmentId;

        let path = `/v1/schedule/appointment/${appointmentId}`

        if(payload.reschedule) {
            path = path.concat(`?reschedule=true`);
        } else if(payload.markNoShow) {
            path = path.concat(`?markNoShow=true`);
        }

        await fetchData(path, {
            method: 'Delete',
        });
    },

    async loadClinicianEvents(context, payload) {
        let clinicianId = payload.clinicianId;
        let currentDate = payload.date;
        let timezone = payload.timezone;
        let startDate = addDays(currentDate, -8).toISOString().split('T')[0];
        let endDate = addDays(currentDate, 8).toISOString().split('T')[0];

        let responseData = await fetchData(`/v1/schedule/clinician?timezone=${timezone}&start=${startDate}&end=${endDate}&clinician=${clinicianId}&type=training`);

        // getting appointments that are made
        const events = [];

        if(responseData.clinicianAppointments) {
            responseData.clinicianAppointments.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    summary: event.summary,
                    type: event.type,
                    presence: event.presence,
                    location: event.location,
                    notes: event.notes,
                    classNames: event.classNames,
                    editable: event.editable,
                    link: event.url,
                }
                events.push(eventsList);
            });
        }

        // pushing events restricted time to events array
        if(responseData.productRestrictedTime) {
            responseData.productRestrictedTime.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    editable: event.editable,
                    display: event.display,
                    classNames: event.classNames,
                }
                events.push(eventsList);
            });
        }

        //pushing clinician restricted time to events array
        if(responseData.clinicianRestrictedTime) {
            responseData.clinicianRestrictedTime.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    editable: event.editable,
                    display: event.display,
                    classNames: event.classNames,
                }
                events.push(eventsList);
            });
        }

        // getting clinician appointments
        if(responseData.appointments) {
            responseData.appointments.forEach(function (event) {
                const eventsList = {
                    id: event.token,
                    title: event.summary,
                    start: event.startAt,
                    end: event.endAt,
                    editable: event.editable,
                    display: event.display,
                    classNames: event.classNames,
                }
                events.push(eventsList);
            });
        }

        context.commit("setEvents", events);

        //getting available clinicians
        if (responseData.availableClinicians.length === 0 || !responseData.availableClinicians) {
            context.commit("setAvailableClinicians", "No Clinician");
        } else {
            context.commit("setAvailableClinicians", responseData.availableClinicians);
        }

        //getting selected clinician's name
        let selectedClinicianName = '';
        responseData.availableClinicians.forEach(function (user) {
            if (user.selected === true) {
                selectedClinicianName = user.name;
            }
        });

        context.commit("setSelectedClinician", selectedClinicianName);
    },

    async loadEditClinicianAppointmentForm(context, payload) {
        let timezone = payload.timezone;
        const appointmentId = payload.appointmentId

        const responseData = await fetchData(`/v1/schedule/clinician/${appointmentId}/edit?timezone=${timezone}`);

        const apptEditForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                }
                apptEditForm.push(editForm);
            });
        }

        context.commit("setEditAppointmentForm", apptEditForm);
        responseData.details && context.commit("setPatientInfo", responseData.details);

    },

    async editClinicianAppointment(context, payload) {
        let appointmentId = payload.appointmentId;

        await fetchData(`/v1/schedule/clinician/${appointmentId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        });
    },

    async deleteClinicianAppointment(context, payload) {
        const appointmentId = payload.appointmentId;

        await fetchData(`/v1/schedule/clinician/${appointmentId}`, {
            method: 'Delete',
        });
    },

    async searchAppointment(context, payload) {
        const firstName = payload.firstName;
        const lastName = payload.lastName;
        const phone = payload.phone;
        const email = payload.email;

        let path = 'v1/schedule/search?';

        if(firstName) {
            path = path.concat(`firstName=${firstName}`);
        }

        if(!firstName && lastName) {
            path = path.concat(`lastName=${lastName}`);
        } else if(firstName && lastName){
            path = path.concat(`&lastName=${lastName}`);
        }

        if(!firstName && !lastName && phone) {
            path = path.concat(`phone=${phone}`);
        } else if((firstName || lastName) && phone){
            path = path.concat(`&phone=${phone}`);
        }

        if(!firstName && !lastName && !phone && email) {
            path = path.concat(`email=${email}`);
        } else if((firstName || lastName || phone) && email) {
            path = path.concat(`&email=${email}`);
        }

        const responseData = await fetchData(path);

        responseData.appointments && context.commit("setAppointmentSearchResult", responseData.appointments);
    },

    async addressToTimeZone(context, payload) {
        const responseData = await fetchData(`/v1/schedule/timeZone`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });

        if(!responseData.timeZone || responseData.timeZone.length === 0) {
            context.commit('setTimeZone', 'error in address');
        } else {
            context.commit('setTimeZone', responseData.timeZone.slug);
        }
    },

    async markAppointmentAsNoShow(context, payload) {
        const appointmentId = payload.appointmentId;

        await fetchData(`/v1/schedule/appointment/${appointmentId}/noShow`, {
            method: 'PUT',
        });
    },

    async loadSoonestAvailableAppointmentsIn7Days(context, payload) {
        let productId = payload.productId;
        let timezone = payload.timezone;
        let state = payload.state;

        let path = '';

        if(payload.thirdParty) {
            path = `v1/liaison/schedule/soonestAvailable?timezone=${timezone}&state=${state}&product=${productId}`;
        } else {
            path = `v1/schedule/appointment/soonestAvailable?timezone=${timezone}&state=${state}&product=${productId}`;
        }

        if(payload.date){
            path = path.concat(`&date=${payload.date}`);
        }

        let responseData = await fetchData(path);

        //getting available clinicians
        if (responseData.timeSlots.length === 0 || !responseData.timeSlots) {
            context.commit("setSoonestAvailableTimes", "No Availability");
        } else {
            const availableTimes = [];

            responseData.timeSlots.forEach(function (slot) {
                const regCategory = {
                    summary: slot.summary,
                    startAt: slot.startAt,
                    endAt: slot.endAt,
                    startAtFormatted: slot.startAtFormatted,
                    endAtFormatted: slot.endAtFormatted,
                    clinician: slot.clinician,
                }
                availableTimes.push(regCategory);
            });

            context.commit("setSoonestAvailableTimes", availableTimes);
        }
    },

    async getSetupCustomFields(context, apptId) {
        const responseData = await fetchData(`/v1/schedule/appointment/getSetupCustomFields?apptId=${apptId}`);

        const setupForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                }
                setupForm.push(editForm);
            });
        }

        context.commit("setSetupForm", setupForm);
    },

    async saveSetupForm(context, payload) {
        await fetchData(`/v1/schedule/appointment/saveSetupCustomFields`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
    }
}

