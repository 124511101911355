export default {
    setServices(state, payload){
        state.services = payload;
    },
    setClients(state, payload){
        state.clients = payload;
    },
    setSelectedService(state, payload){
        state.selectedService = payload;
    },
    setPatients(state, payload){
        state.patients = payload;
    },
    setScheduleAttemptCustomFields(state, payload) {
        state.scheduleAttemptCustomFields = payload;
    }
}