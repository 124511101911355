export default {
	users(state) {
		return state.users;
	},
	hasUsers(state) {
		return state.users && state.users.length > 0;
	},
	userEditForm(state) {
		return state.userEditForm;
	},
	createUserForm(state) {
		return state.createUserForm;
	},
	numOfUsers(state) {
		return state.users.length;
	},
	userAvailability(state){
		return state.userAvailability;
	},
	editUserAvailability(state){
		return state.editUserAvailability;
	},
	productOfUsers(state){
		return state.productOfUsers;
	},
	userLicense(state){
		return state.userLicense;
	},
	getUserRole(state){
		return state.userRole;
	}


}


