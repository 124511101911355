<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
}
</script>

<style scoped>
.card {
  border: 1px solid rgba(0, 131, 202, 0.50);
  border-radius: 12px;
  /*box-shadow: 0 2px 8px rgba(0, 131, 202, 0.15);*/
  padding: 1rem;
  margin: 12px 1px;
}
</style>