export default {
	setActiveUsers(state, payload) {
		state.users = payload
	},
	userEditForm(state, payload) {
		state.userEditForm = payload
	},
	createUserForm(state, payload){
		state.createUserForm = payload
	},
	userAvailability(state, payload){
		state.userAvailability = payload;
	},
	editUserAvailability(state, payload){
		state.editUserAvailability = payload;
	},
	editUserLicense(state, payload){
		state.userLicense = payload;
	},
	setProductOfUser(state, payload) {
		state.productOfUsers = payload;
	},
	setUserRole(state, payload){
		state.userRole = payload;
	}
}