<template>
  <nav class="navbar navbar-expand-lg" :class="env">
    <div class="container-fluid container">
      <a href="#"><img class="logo" :src="logo" alt=""/></a>
      <router-link v-if="isLoggedIn" class="header-link" to="/dashboard"><a><font-awesome-icon :icon="['fas', 'table']"></font-awesome-icon></a> Dashboard</router-link>
      <router-link v-if="isLoggedIn" class="header-link" to="/reports"><a><font-awesome-icon :icon="['fas', 'file']"></font-awesome-icon></a> Reports</router-link>

      <div v-if="isLoggedIn" class="header-link">
        <a :href="documentationLink" target="_blank" rel="noopener noreferrer" id="docs-link">
          <font-awesome-icon :icon="['fas', 'book']"></font-awesome-icon> Documentation
        </a>
      </div>

      <div v-if="isLoggedIn && isMobile" class="btn-group dropdown-toggle-split" style="float: right">
        <div class="white-text" v-if="allowedOrgs.length === 1">{{selectedOrg}}</div>
        <button v-if="selectedOrg && allowedOrgs.length > 1" type="button" class="btn btn-secondary dropdown-toggle change-org-button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ selectedOrg }}
        </button>
        <ul v-if="allowedOrgs.length>1" class="dropdown-menu dropdown-menu-end">
          <li v-for="org in allowedOrgs" :key="org.token"><button @click="changeActiveOrg(org.token)" class="dropdown-item" type="button">{{ org.name }}</button></li>
        </ul>
      </div>

      <button v-if="isLoggedIn" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"><img class="profile-photo-small" :src = profilePhoto alt=""/></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
<!--          <li class="nav-item" v-if="isLoggedIn && isMobile">-->
<!--            <router-link class="header-link" to="/dashboard"><a><font-awesome-icon :icon="['fas', 'table']"></font-awesome-icon></a> &nbsp; Dashboard</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item" v-if="isLoggedIn">-->
<!--            <router-link class="header-link" to="/schedule"> <a><font-awesome-icon :icon="['fas', 'calendar-day']"></font-awesome-icon></a> &nbsp;Schedule </router-link>-->
<!--          </li>-->
          <li class="nav-item" v-if="isLoggedIn && isMobile" style="margin-top: 20px">
            <a style="padding-left: 0; cursor: pointer;" @click="editProfileLink" class="header-link"><a><font-awesome-icon :icon="['fas', 'edit']"></font-awesome-icon></a> &nbsp;Edit Profile</a>
          </li>
          <li class="nav-item" v-if="isLoggedIn && isMobile">
            <a style="padding-left: 0; cursor: pointer" class=" header-link" @click="logout"><a><font-awesome-icon :icon="['fas', 'arrow-right']"></font-awesome-icon></a> &nbsp;Log out</a>
          </li>
        </ul>
        <div v-if="isLoggedIn && !isMobile" class="btn-group dropdown-toggle-split">
          <div class="white-text" v-if="allowedOrgs.length === 1">{{selectedOrg}}</div>
          <button v-if="selectedOrg && allowedOrgs.length > 1" type="button" class="btn btn-secondary dropdown-toggle change-org-button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedOrg }}
          </button>
          <ul v-if="allowedOrgs.length>1" class="dropdown-menu dropdown-menu-end">
            <li v-for="org in allowedOrgs" :key="org.token"><button @click="changeActiveOrg(org.token)" class="dropdown-item" type="button">{{ org.name }}</button></li>
          </ul>
        </div>
        <div v-if="isLoggedIn && !isMobile" class="btn-group dropdown-menu-sm" >
          <a v-if="profilePhoto" class="dropdown-toggle header-link profile-button" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="profile-photo" :src = profilePhoto  alt=""/>
          </a>
          <a v-if="!profilePhoto" class="dropdown-toggle header-link profile-button" data-bs-toggle="dropdown" aria-expanded="false">
            <font-awesome-icon  :icon="['fas', 'user']"></font-awesome-icon>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><button @click="editProfileLink" class="dropdown-item" type="button">Edit Profile</button></li>
            <li><button class="dropdown-item" type="button" @click="logout">Log out</button></li>
          </ul>
        </div>
      </div>

    </div>
  </nav>


</template>


<script>
import { useWindowSize } from 'vue-window-size';

export default {
  setup() {
    const { width } = useWindowSize();
    return {
      windowWidth: width,
    };
  },
  data() {
    return {
      userToken: this.$store.getters['auth/userToken'],
      logo: require('../../assets/images/logo.png'),
      baseURI: 'https://docs.',
      currentDomain: window.location.hostname,
    }
  },
  created() {
    if(this.isLoggedIn){
      this.$store.dispatch('orgs/loadOrganizations');
    }
    this.selectedOrg = this.$store.getters['auth/activeOrg'];
  },
  beforeUpdate() {
    this.selectedOrg = this.$store.getters['auth/activeOrg'];
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    orgsList() {
      return this.$store.getters['orgs/activeOrganizations'];
    },
    isMobile(){
      return this.windowWidth <= 991;
    },
    profilePhoto(){
      return this.$store.getters['auth/getProfilePhoto'];
    },
    selectedOrg(){
      return this.$store.getters['auth/activeOrg'];
    },
    allowedOrgs(){
      return this.$store.getters['orgs/getAllowedOrgs'];
    },
    env(){
      return this.$store.getters['auth/getEnv'];
    },
    documentationLink() {
      return this.baseURI + this.currentDomain;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');
      await this.$router.push('/login');
    },
    editProfileLink() {
      this.$router.push('/users/' + this.userToken)
    },
    async changeActiveOrg(orgToken){
      // this.selectedOrg = this.$store.getters['orgs/getAllowedOrgs'].find(
      //     (org) => org.token === orgToken).name;
      // console.log(orgToken)
      try {
        await this.$store.dispatch('orgs/changeActiveOrg', {
          orgId: 	orgToken
        })
      } catch (error) {
        this.error = error.message || 'Something went wrong';
      }
      this.$router.go();
    }

  }
}
</script>



