<template>
  <div class="container toastification">
    <div class="toast-title">{{toastHeader}}</div>
    <div class="toast-message">{{toastMessage}}</div>
  </div>
</template>

<script>
export default {
  props: {
    serverMessage: {
      required: false,
    },
  },
  name: "BaseToastification",
  computed: {
    toastMessage(){
      return this.serverMessage.message;
    },
    toastHeader(){
      return this.serverMessage.title;
    }
  },
}
</script>

<style scoped>

</style>