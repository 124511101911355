export default {
    setProducts(state, payload) {
        state.products = payload;
    },
    setAvailableTimes(state, payload) {
        state.availableTimes = payload;
    },
    setSelectedClinician(state, payload) {
        state.selectedClinician = payload;
    },
    setChosenDate(state, payload) {
        state.chosenDate = payload;
    },
    setReCaptchaKey(state, payload) {
        state.reCaptchaKey = payload;
    },
    setTimeZone(state, payload) {
        state.addressToTimeZoneResponse = payload;
    },
    setThirdPartyAvailableClinicians(state, payload) {
        state.thirdPartyAvailableClinicians = payload;
    },
    setScheduledClinician(state, payload) {
        state.scheduledClinician = payload
    },
    setScheduledAppointmentDetails(state, payload) {
        state.scheduledAppointmentDetails = payload
    },
    setAddAppointmentForm(state, payload) {
        state.addAppointmentForm = payload;
    }
}