export default {
	setUser(state, payload){
		state.isLoggedIn = payload.isLoggedIn;
		state.userToken = payload.userToken;
		state.didAutoLogout = false;
	},
	setActiveOrganization(state, payload) {
		state.activeOrg = payload.activeOrg;
	},
	setGoogleClientId(state, payload){
		state.googleClientId = payload;
	},
	setProfilePhoto(state, payload){
		state.profilePhoto = payload;
	},
	setAppVersion(state, payload){
		state.appVersion = payload;
	},
	setAutoLogout(state){
		state.didAutoLogout = true;
	},
	setErrorMessages(state, payload){
		state.serverError = payload;
	},
	setEnv(state, payload){
		state.env = payload
	}

};