import store from "./index.js";
import router from "../router";

async function fetchData(url, options) {
    if (!options){
        options = {};
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    store.commit('schedule/setCurrentTimezone');

    options.headers = {
        'Content-Type': options["Content-Type"] ? options["Content-Type"] : 'application/json',
        'Accept': options["Accept"] ? options["Accept"] : 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept-Timezone': timezone,
    };

    const httpResponse = await fetch(url, options).then((response) => {
        if (response.ok) {
            return response;
        } else if (response.status === 401) {
            store.dispatch('auth/logout');
            router.push('/login');
        } else if(response.status >= 500 && response.status < 600) {
            let serverErrorMessage = [
                {
                    "type": "error",
                    "title": "Something went wrong",
                    "message": "Please try again"
                }
            ];
            store.commit('auth/setErrorMessages', serverErrorMessage);
            throw new Error('Server error');
        } else {
            return response;
        }
    });

    //console.log('httpResponse',httpResponse);


    // logging user out if user is inactive for 7200 seconds
    let expiresIn = 7200;
    if(store.getters["auth/isAuthenticated"]){
        await store.dispatch('auth/clearTimer');
        await store.dispatch('auth/setTimer', expiresIn * 1000)
    }
    let responseBody = {};
    try {
        responseBody = await httpResponse.json();
    } catch (error) {
        console.log(error);
    }
    // console.log(responseBody);

    // setting toastify messages
    if(responseBody.messages){
        store.commit("schedule/setMessages", responseBody.messages);
    }

    // setting current env
    if(responseBody.env){
        store.commit("auth/setEnv", responseBody.env);
    }

    // setting user role
    if (responseBody.role){
        store.commit("users/setUserRole", responseBody.role);
    }

    // setting software version
    if(responseBody.version){
        store.commit("auth/setAppVersion", responseBody.version);
    }

    // setting allowed organizations
    if(responseBody.allowedOrganizations){
        store.commit('orgs/setAllowedOrgs', responseBody.allowedOrganizations);
    }

    // setting error messages
    if (httpResponse.status === 406) {
        store.commit('auth/setErrorMessages', responseBody.messages);
        throw new Error(JSON.stringify(responseBody.error));
    } else if(httpResponse.status >= 400 && httpResponse.status < 500){
        store.commit('auth/setErrorMessages', responseBody.messages);
        // console.log(httpResponse.message);
    } else if(httpResponse.status >= 500 && httpResponse.status < 600) {
        let serverErrorMessage = [
            {
                "type": "error",
                "title": "Something went wrong",
                "message": "Please try again"
            }
        ];
        store.commit('auth/setErrorMessages', serverErrorMessage);
        throw new Error('Server error');
    }



    // setting active organization
    if (responseBody.activeOrganization){
        responseBody.allowedOrganizations.every(function (organization) {
            if(organization.token === responseBody.activeOrganization) {
                store.commit("auth/setActiveOrganization", {
                    activeOrg: organization.name
                });
                return false;
            }
            return true;
        })
    }

    return responseBody;
}

export {fetchData};
