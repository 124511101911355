export default {
    activeOrganizations(state){
        return state.orgsList;
    },
    hasOrganizations(state) {
        return state.orgsList && state.orgsList.length > 0;
    },
    createOrganizationForm(state) {
        return state.createOrgsForm;
    },
    editOrganizationForm(state) {
        return state.editOrgsForm;
    },
    numOfOrganizations(state) {
        return state.orgsList.length;
    },
    getAllowedOrgs(state){
        return state.allowedOrgs;
    }



}