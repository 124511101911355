import {fetchData} from "../use-fetch";
import {reCaptchaKey} from "@/main";

export default {
    async loadProducts(context, payload) {
        let slug = payload.slug;

        const responseData = await fetchData(`v1/liaison/schedule/${slug}`, {});

        const products = [];

        if(responseData.products) {
            responseData.products.forEach(function (product) {
                const regCategory = {
                    name: product.name,
                    id: product.token,
                    type: product.type,
                    licensing: product.licensing,
                }
                products.push(regCategory);
            });
        }

        context.commit("setProducts", products);
    },

    async loadAvailableTimes(context, payload) {
        let slug = payload.slug;
        let productId = payload.productId;
        let timezone = payload.timezone;
        let state = payload.state;

        let path = `v1/liaison/schedule/${slug}/${productId}?timezone=${timezone}&state=${state}`;

        if(payload.date){
            path = path.concat(`&date=${payload.date}`);
        }

        if(payload.chosenClinician){
            path = path.concat(`&clinician=${payload.chosenClinician}`);
        }

        let responseData = await fetchData(path);

        //getting available clinicians
        if (!responseData.availableClinicians || responseData.availableClinicians.length === 0) {
            context.commit("setThirdPartyAvailableClinicians", "No Clinician");
        } else {
            context.commit("setThirdPartyAvailableClinicians", responseData.availableClinicians)
        }

        const availableTimes = [];

        if(responseData.timeSlots) {
            responseData.timeSlots.forEach(function (slot) {
                const regCategory = {
                    summary: slot.summary,
                    startAt: slot.startAt,
                    endAt: slot.endAt,
                    startAtFormatted: slot.startAtFormatted,
                    endAtFormatted: slot.endAtFormatted,
                }
                availableTimes.push(regCategory);
            });
        }

        context.commit("setAvailableTimes", availableTimes);

        if(availableTimes.length > 0) {
            context.commit("setChosenDate", availableTimes[0].start_at);
        } else if(availableTimes.length === 0) {
            context.commit("setChosenDate", payload.date + " 00:00:00");
        }

        responseData.selectedClinician && context.commit("setSelectedClinician", responseData.selectedClinician);

        if(responseData.reCaptchaSiteKey) {
            context.commit("setReCaptchaKey", responseData.reCaptchaSiteKey);
            reCaptchaKey(responseData.reCaptchaSiteKey);
        }
    },

    async addAppointment(context, payload) {
        let slug = payload.slug;
        let productId = payload.productId;

        const responseData = await fetchData(`/v1/liaison/schedule/${slug}/${productId}`, {
            method: 'POST',
            body: JSON.stringify(payload)
        });

        if(responseData.clinician) {
            let fullName = responseData.clinician.firstName + " " + responseData.clinician.lastName
            context.commit('setScheduledClinician', fullName);
        }

        if (!responseData.appointment || responseData.appointment.length === 0) {
            responseData.messages.forEach(function (message) {
                if(message.title === 'Scheduling Conflict') {
                    context.commit('setScheduledAppointmentDetails', "Conflict");
                } else {
                    context.commit('setScheduledAppointmentDetails', "ClickUp Error");
                }
            })
        } else {
            context.commit('setScheduledAppointmentDetails', responseData.appointment);
        }
    },

    async addressToTimeZone(context, payload) {
        const responseData = await fetchData(`/v1/schedule/timeZone`, {
            method: 'POST',
            body: JSON.stringify(payload)
        });

        if(!responseData.timeZone || responseData.timeZone.length === 0) {
            context.commit('setTimeZone', 'error in address');
        } else {
            context.commit('setTimeZone', responseData.timeZone.slug);
        }
    },

    async addAppointmentInService(context, payload) {
        let slug = payload.slug;
        let productId = payload.productId;

        const responseData = await fetchData(`/v1/inService/schedule/${slug}/${productId}`, {
            method: 'POST',
            body: JSON.stringify(payload)
        });

        if(responseData.clinician) {
            let fullName = responseData.clinician.firstName + " " + responseData.clinician.lastName
            context.commit('setScheduledClinician', fullName)
        }

        if (!responseData.appointment || responseData.appointment.length === 0) {
            context.commit('setScheduledAppointmentDetails', "Conflict");
        } else {
            context.commit('setScheduledAppointmentDetails', responseData.appointment)
        }
    },

    async loadAppointmentCreateForm(context, payload) {
        let productId = payload.productId;

        const responseData = await fetchData(`v1/liaison/schedule/new?product=${productId}`, {});

        responseData.formStructure && context.commit('setAddAppointmentForm', responseData.formStructure);

    },
}