export default {
    setActiveOrganizations(state, payload){
        state.orgsList = payload
    },
    editOrganizationForm(state, payload){
        state.editOrgsForm = payload
    },
    createOrganizationForm(state, payload) {
        state.createOrgsForm = payload
    },
    setAllowedOrgs(state, payload){
        state.allowedOrgs = payload
    }


}