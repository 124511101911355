import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions";
export default {
    namespaced: true,
    state() {
        return {
            orgsList: [],
            createOrgsForm: [],
            editOrgsForm:[],
            allowedOrgs: [],


        }
    },
    mutations,
    actions,
    getters,
}