export default {
    registeredProducts(state){
        return state.products;
    },
    hasProducts(state) {
        return state.products && state.products.length > 0;
    },
    createProductForm(state) {
        return state.createProductForm;
    },
    editProductForm(state) {
        return state.productEditForm;
    },
    states(state){
        return state.states;
    },
    availableTimes(state){
        return state.availableTimes;
    },
    editAvailableTimes(state) {
        return state.editAvailableTimes;
    },
     editLicensing(state) {
        return state.licensing;
    },
    editInState(state){
        return state.inState;
    },
    usersOfProduct(state){
        return state.usersOfProduct;
    },

}