export default {
    setRegisteredProduct(state, payload) {
        state.products = payload;
    },
    editProductForm(state, payload){
        state.productEditForm = payload;
    },
    createProductForm(state, payload) {
        state.createProductForm = payload;
    },
    createAvailableTimesForm(state, payload){
        state.availableTimes = payload;
    },
    editAvailableTimesForm(state, payload){
        state.editAvailableTimes = payload;
    },
    editLicensing(state, payload){
        state.licensing = payload;
    },
    editInState(state, payload) {
        state.inState = payload;
    },
    setUsersOfProduct(state, payload){
        state.usersOfProduct = payload
    },




}