import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions";
export default {
    namespaced: true,
    state() {
        return {
            services: [],
            clients: [],
            selectedService: [],
            patients: [],
            scheduleAttemptCustomFields: [],


        }
    },
    mutations,
    actions,
    getters,
}