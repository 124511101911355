import {fetchData} from "../use-fetch.js"

export default {
    async loadProducts(context) {
        const responseData = await fetchData('v1/product', {});

        const products = [];

        if(responseData.products) {
            responseData.products.forEach(function (product) {
                const regProduct = {
                    name: product.name,
                    id: product.token,
                    type: product.type,
                    licensing: product.licensing,
                    availability: product.availableTimes,
                    defaultApptTime: product.defaultApptTime,
                    users: product.users,
                    category: product.categoryName,
                    categoryId: product.categoryToken,
                }
                products.push(regProduct);
            });
        }

        context.commit("setRegisteredProduct", products);
    },

    async loadEditProductForm(context, payload) {
        const productId = payload.productId;

        const responseData = await fetchData(`/v1/product/${productId}/edit`, {});

        responseData.availableTimes && context.commit("editAvailableTimesForm", responseData.availableTimes);
        responseData.inState && context.commit('editInState', responseData.inState);
        responseData.licensing && context.commit('editLicensing', responseData.licensing);

        const editProductForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options
                }
                editProductForm.push(editForm);
            });
        }

        context.commit("editProductForm", editProductForm);
    },

    async saveEditProductForm(context, payload) {
        const productId = payload.productId;

        await fetchData(`/v1/product/${productId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        });
    },

    async createProductForm(context) {
        const responseData = await fetchData('/v1/product/new');

        const createProductForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const createForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                }
                createProductForm.push(createForm);
            });
        }

        context.commit("createProductForm", createProductForm);
        responseData.availableTimes && context.commit("createAvailableTimesForm", responseData.availableTimes);
    },

    async saveCreateProductForm(context, payload) {
        await fetchData('v1/product', {method: 'POST',
            body: JSON.stringify(payload),})
    },

    async deleteProduct(context, payload) {
        const productId = payload.productId;

        await fetchData(`/v1/product/${productId}`, {
            method: 'Delete',
        });
    },

    async loadUsersOfProduct(context, payload) {
        const productId = payload.productId;

        const responseData = await fetchData(`/v1/product/${productId}/user`);

        responseData.users && context.commit("setUsersOfProduct", responseData.users);
    },

    async addUsersToProduct(context, payload){
        const productId = payload.productId;

        await fetchData(`/v1/product/${productId}/user`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
    },

    async deleteUsersOfProduct(context, payload){
        const productId = payload.productId;
        const userId = payload.userId;

        await fetchData(`/v1/product/${productId}/user/${userId}`, {
            method: 'Delete',
        });
    }

}