import {createStore} from "vuex";
import authModule from './auth/index.js';
import usersModule from './users/index.js';
import orgModule from './organizations/index.js';
import productModule from './products/index.js';
import scheduleModule from './schedule/index.js';
import categoriesModule from './categories/index.js';
import thirdPartySchedulingModule from './thirdPartyScheduling/index.js';
import clickUpPatientModule from './clickUpPatient/index.js';
import reportModule from './report/index.js';


const store= createStore({
	modules:{
		auth: authModule,
		users: usersModule,
		orgs: orgModule,
		products: productModule,
		schedule: scheduleModule,
		category: categoriesModule,
		thirdParty: thirdPartySchedulingModule,
		clickUpPatient: clickUpPatientModule,
		report: reportModule
	},
	state() {

	},
	getters: {

	}
})

export default store;