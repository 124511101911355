import {createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store/index.js";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@vueform/multiselect/themes/default.css";
import VCalendar from 'v-calendar';
import vue3GoogleLogin from 'vue3-google-login';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Multiselect from '@vueform/multiselect';
import VueClipboard from 'vue3-clipboard';



import BaseCard from "./components/BaseComponents/BaseCard";
import BaseButton from "./components/BaseComponents/BaseButton";
import BaseSpinner from "./components/BaseComponents/BaseSpinner";
import BaseDialog from "./components/BaseComponents/BaseDialog";
import BaseForm from "./components/BaseComponents/BaseForm";
import BaseModal from "./components/BaseComponents/BaseModal";
import BaseToast from "./components/BaseComponents/BaseToast";



import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons/faUserCircle";
import {faTools} from "@fortawesome/free-solid-svg-icons/faTools";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faCompress} from "@fortawesome/free-solid-svg-icons/faCompress";
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import {faTable} from "@fortawesome/free-solid-svg-icons/faTable";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faBuilding} from "@fortawesome/free-solid-svg-icons/faBuilding";
import {faToolbox} from "@fortawesome/free-solid-svg-icons/faToolbox";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faSortAlphaDown} from "@fortawesome/free-solid-svg-icons/faSortAlphaDown";
import {faCalendarTimes} from "@fortawesome/free-solid-svg-icons/faCalendarTimes";
import {faRedo} from "@fortawesome/free-solid-svg-icons/faRedo";
import {faUserTimes} from "@fortawesome/free-solid-svg-icons/faUserTimes";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";
import {faCalendarPlus} from "@fortawesome/free-solid-svg-icons/faCalendarPlus";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {faUpload} from "@fortawesome/free-solid-svg-icons/faUpload";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons/faUserEdit";



const app = createApp(App);


library.add(faTrashAlt);
library.add(faEdit);
library.add(faArrowLeft);
library.add(faUser);
library.add(faBan);
library.add(faPlus);
library.add(faMinus);
library.add(faTimes);
library.add(faUserCircle);
library.add(faTools);
library.add(faSearch);
library.add(faCompress);
library.add(faCalendarDay);
library.add(faTable);
library.add(faUsers);
library.add(faBuilding);
library.add(faToolbox);
library.add(faBars);
library.add(faArrowRight);
library.add(faCircle);
library.add(faLayerGroup);
library.add(faCopy);
library.add(faInfoCircle);
library.add(faSortAlphaDown);
library.add(faCalendarTimes);
library.add(faRedo);
library.add(faUserTimes);
library.add(faFile);
library.add(faCalendarPlus);
library.add(faClock);
library.add(faUpload);
library.add(faBook);
library.add(faCheckCircle);
library.add(faUserEdit);


app.component('font-awesome-icon', FontAwesomeIcon);

app.config.productionTip = false;

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('base-form', BaseForm);
app.component('base-modal', BaseModal);
app.component('base-toast', BaseToast);

app.use(Toast, {
    // Setting the global default position
    position: POSITION.BOTTOM_LEFT,
    timeout: 4000,
});

app.use(VCalendar);
app.use(store);
app.use(router);
app.use(Multiselect);

app.use(VueClipboard,{
    autoSetContainer: true,
    appendToBody: true,
})



function googleToken(client_id) {
    app.use(vue3GoogleLogin, {
        clientId: client_id,
        // scope: 'https://www.googleapis.com/auth/calendar',
        // prompt: 'consent'
    });
    store.commit('auth/setGoogleClientId', client_id)
}

export {googleToken};

function reCaptchaKey(key){
    app.use(VueReCaptcha, {
        siteKey: key
    })
}

export {reCaptchaKey};


app.mount('#app');
