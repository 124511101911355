<template>
  <footer class="footer" style="text-align: center; font-size: small;">
    <span>©{{currentYear}} rtNOW  </span>
    <span style="margin-left: 5%">{{appVersion}}</span>
  </footer>

</template>

<script>
export default {
  computed: {
    appVersion() {
      return this.$store.getters['auth/getAppVersion'];
    },
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>



