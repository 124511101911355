export default {
	isAuthenticated(state){
		return !!state.isLoggedIn;
	},
	userToken(state){
		return state.userToken;
	},
	activeOrg(state){
		return state.activeOrg;
	},
	getGoogleClientId(state){
		return state.googleClientId;
	},
	getProfilePhoto(state){
		return state.profilePhoto;
	},
	getAppVersion(state){
		return state.appVersion;
	},
	getErrorMessage(state){
		return state.serverError;
	},
	didAutoLogout(state){
		return state.didAutoLogout;
	},
	getEnv(state){
		return state.env;
	}




}