export default {
    getReportsList(state) {
        return state.reportsList;
    },
    getClientSchedulerReportData(state) {
        return state.clientSchedulerReportData;
    },
    getClickUpTaskUploadsReportData(state) {
        return state.clickUpTaskUploadsReportData;
    },
    getClickUpTaskUploadEditForm(state) {
        return state.clickUpTaskUploadEditForm;
    },
    getInternalSchedulerReportData(state) {
        return state.internalSchedulerReportData;
    },
    getSetupErrorsData(state) {
        return state.setupErrorsData;
    }

}