export default {
    getProducts(state) {
        return state.products;
    },
    getAvailableTimes(state) {
        return state.availableTimes;
    },
    getSelectedClinician(state) {
        return state.selectedClinician;
    },
    getDate(state) {
        return state.chosenDate;
    },
    getReCaptchaKey(state) {
        return state.reCaptchaKey;
    },
    getAddressToTimeZoneResponse(state) {
        return state.addressToTimeZoneResponse;
    },
    getThirdPartyAvailableClinicians(state) {
        return state.thirdPartyAvailableClinicians;
    },
    getScheduledClinician(state) {
        return state.scheduledClinician;
    },
    getScheduledAppointmentDetails(state) {
        return state.scheduledAppointmentDetails;
    },
    getAddAppointmentForm(state) {
        return state.addAppointmentForm;
    }
}