function formatDate(date) {
    var d = new Date(date);
    var hh = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    var dd = "AM";
    var h = hh;
    if (h >= 12) {
        h = hh - 12;
        dd = "PM";
    }
    if (h == 0) {
        h = 12;
    }
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    var pattern = new RegExp("0?" + hh + ":" + m + ":" + s);
    var replacement = h + ":" + m;
    replacement += " " + dd;

    if(date){
        return date.replace(pattern, replacement);
    }

}

export {formatDate}