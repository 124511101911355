import {fetchData} from "../use-fetch.js";
import {googleToken} from "@/main";

let timer;

export default {
	async login(context, payload){
		return context.dispatch('auth', {
			...payload
		})
	},

	async auth(context, payload) {
		const responseData = await fetchData('/v1/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: payload.email,
				password: payload.password,
			}),
		});

		responseData.isLoggedIn && localStorage.setItem('isLoggedIn', responseData.isLoggedIn);
		responseData.userToken && localStorage.setItem('userToken', responseData.userToken);

		context.commit('setUser', {
			isLoggedIn: responseData.isLoggedIn,
			userToken: responseData.userToken,
		});
	},

	tryLogin(context) {
		const isLoggedIn = localStorage.getItem('isLoggedIn');
		const userToken = localStorage.getItem('userToken');
		const profilePhoto = localStorage.getItem('profilePhoto');

		const expiresInTime = +localStorage.getItem('expiryDate') - new Date().getTime();

		if(expiresInTime < 0){
			return;
		}

		if (isLoggedIn && userToken) {
			context.commit('setUser', {
				isLoggedIn: isLoggedIn,
				userToken: userToken,
			});
			context.commit('setProfilePhoto', profilePhoto);
		}
	},

	async logout(context) {
		await fetchData('/v1/logout', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({}),
		});

		await localStorage.removeItem('isLoggedIn');
		await localStorage.removeItem('userToken');
		await localStorage.removeItem('tokenExpirationRefreshed');

		context.commit('setUser', {
			isLoggedIn: false,
			userToken: null,
		});

		context.commit('setActiveOrganization', {
			activeOrg: ''
		});

		clearTimeout(timer);
	},

	async resetPassword(context, payload) {
		await fetchData('/v1/login/forgot', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: payload.email,
			}),
		});
	},

	async createPassword(context, payload) {
		await fetchData('/v1/login/reset', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				password: payload.password,
				passwordConfirm: payload.passwordConfirm,
				token: payload.token,
			}),
		});
	},

	async callToIndex() {
		const responseData = await fetchData('/v1/login',);
		//console.log(responseData);
		await googleToken(responseData.googleClientId);
	},

	async sendGoogleToken(context, payload){
		const responseData = await fetchData('/v1/login/validate', {
			method: 'POST',
			body: JSON.stringify({
				googleToken: payload.googleToken,
			}),
		});

		// const expiresIn = +localStorage.getItem('tokenExpirationRefreshed') * 1000;

		localStorage.setItem('isLoggedIn', responseData.isLoggedIn);
		localStorage.setItem('userToken', responseData.userToken);
		localStorage.setItem('profilePhoto', responseData.avatar);

		//
		// timer = setTimeout(function(){
		// 	context.dispatch('autoLogout');
		// }, expiresIn)
		//
		// console.log('timer2', expiresIn)

		context.commit('setUser', {
			isLoggedIn: responseData.isLoggedIn,
			userToken: responseData.userToken,
		});

		context.commit('setProfilePhoto', responseData.avatar);

	},

	autoLogout(context){
		context.dispatch('logout');
		context.commit('setAutoLogout');
	},

	clearTimer(){
		// console.log('clearing timer')
		clearTimeout(timer);
	},

	setTimer(context, expiresIn){
		// console.log('setting timer');
		timer = setTimeout(function(){
			context.dispatch('autoLogout');
		}, expiresIn)
		localStorage.setItem('expiryDate',  new Date().getTime() + expiresIn);
	},
}

