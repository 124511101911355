import {fetchData} from "../use-fetch.js";

export default {
    async loadCategories(context) {
        const responseData = await fetchData('v1/product/category', {});

        const categories = [];

        if(responseData.categories) {
            responseData.categories.forEach(function (category) {
                const regCategory = {
                    id: category.token,
                    name: category.name,
                    slug: category.slug,
                }
                categories.push(regCategory);

            });
        }

        context.commit("setProductCategories", categories);
    },

    async deleteCategory(context, payload) {
        const categoryId = payload.categoryId;

        await fetchData(`/v1/product/category/${categoryId}`, {
            method: 'Delete',
        });
    },

    async getCreateCategoryForm(context, payload) {
        const responseData = await fetchData(`/v1/product/category/new`, {});

        const createCategoryForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const createForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                }
                createCategoryForm.push(createForm);
            });
        }

        context.commit("setCreateCategoryForm", createCategoryForm);
    },

    async createCategory(context, payload) {
        await fetchData('v1/product/category', {
            method: 'POST',
            body: JSON.stringify(payload.formData)
        });
    },

    async getEditCategoryForm(context, payload) {
        const categoryId = payload.categoryId;

        const responseData = await fetchData(`/v1/product/category/${categoryId}/edit`, {});

        const editCategoryForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                }
                editCategoryForm.push(editForm);
            });
        }

        context.commit("setEditCategoryForm", editCategoryForm);
    },

    async editCategory(context, payload) {
        const categoryId = payload.formData.categoryId;

        await fetchData(`/v1/product/category/${categoryId}`, {
            method: 'PUT',
            body: JSON.stringify(payload.formData),
        });
    },

}