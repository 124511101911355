export default {
    setEvents(state,payload){
        state.events = payload;
    },
    setAvailableClinicians(state, payload){
        state.availableClinicians = payload;
    },
    setSelectedClinician(state, payload){
        state.selectedClinician = payload;
    },
    setAddEventForm(state, payload){
        state.addEventForm = payload;
    },
    setEditAppointmentForm(state, payload){
        state.editEventForm = payload;
    },
    setMessages(state, payload){
        state.messages = payload;
    },
    setCurrentTimezone(state,payload){
        state.currentTimezone = payload;
    },
    setPatientInfo(state, payload){
        state.patientInfo = payload;
    },
    setAppointmentSearchResult(state, payload){
        state.appointmentSearchResult = payload;
    },
    setProductScript(state, payload){
        state.productScript = payload;
    },
    setTimeZone(state, payload){
        state.addressToTimeZoneResponse = payload;
    },
    setSoonestAvailableTimes(state, payload) {
        state.soonestAvailableTimes = payload;
    },
    setSchedulingConflict(state, payload) {
        state.schedulingConflict = payload;
    },
    setCreatedApptData(state, payload) {
        state.createdApptData = payload;
    },
    setSetupForm(state, payload) {
        state.setupForm = payload;
    }

}