<template>
    <div @click="$emit('input', value)">

      <label v-if="type !== 'radio' && type !== 'checkbox'"> {{mainLabel}}</label>

      <div v-if="type === 'checkbox'">
        <input
            :type="type"
            class="form-check-input"
            :name="name"
            :value="mainLabel"
            :placeholder="placeholder"
            :class="isError"
            :checked="label === modelValue"
            @change="$emit('update:modelValue', $event.target.value)" /> {{mainLabel}}
      </div>


      <div v-else-if="type === 'radio'">
        {{mainLabel}}
        <input
              :type="type"
              class="form-check-input"
              :name="name"
              :value="label"
              :placeholder="placeholder"
              :class="isError"
              :checked="label === modelValue"
              @change="$emit('update:modelValue', $event.target.value)"/> {{label}}
      </div>


      <div v-else-if="type ==='textarea'">
         <textarea
                ref="textarea"
               :type="type"
               class="form-control"
               :value="modelValue"
               :name="name"
               :placeholder="placeholder"
               :class="isError"
               :disabled="disabled"
               @input="$emit('update:modelValue', $event.target.value)"/>
      </div>


      <div v-else-if="type === 'select'">
        <select
            :class="isError"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            class="form-select"
            aria-label="Default select example">
          <option v-for="option in options" :key="option.value"
                  :class="selectedValue === option.value ? 'dropdown-item active' : 'null'"
                  :value="option.value"
          >{{ option.label }}</option>
        </select>
      </div>


      <div v-else-if="type === 'datetime' && name === 'start_at' && editMode === false">
        <div style="margin-left: 10px">
          {{start_at}}
        </div>
<!--        <v-date-picker v-model="start_at" mode="dateTime" :minute-increment="15">-->
<!--          <template v-slot="{ inputValue, inputEvents }">-->
<!--            <input-->
<!--                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"-->
<!--                :value="inputValue"-->
<!--                v-on="inputEvents"-->
<!--            />-->
<!--          </template>-->
<!--        </v-date-picker>-->
      </div>


      <div v-else-if="type === 'datetime' && name === 'end_at' && editMode === false">
        <div style="margin-left: 10px">
          {{end_at}}
        </div>

<!--        <v-date-picker v-model="end_at" mode="dateTime" :minute-increment="15">-->
<!--          <template v-slot="{ inputValue, inputEvents }">-->
<!--            <input-->
<!--                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"-->
<!--                :value="inputValue"-->
<!--                v-on="inputEvents"-->
<!--            />-->
<!--          </template>-->
<!--        </v-date-picker>-->
      </div>


      <div v-else-if="type === 'datetime' && name === 'start_at'&& editMode === true">
        <div style="margin-left: 10px">
          {{start_at_edit}}
        </div>
<!--        <v-date-picker v-model="start_at_edit" mode="dateTime" :minute-increment="15">-->
<!--          <template v-slot="{ inputValue, inputEvents }">-->
<!--            <input-->
<!--                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"-->
<!--                :value="inputValue"-->
<!--                v-on="inputEvents"-->
<!--            />-->
<!--          </template>-->
<!--        </v-date-picker>-->
      </div>


      <div v-else-if="type === 'datetime' && name === 'end_at'&& editMode === true">
        <div style="margin-left: 10px">
          {{end_at_edit}}
        </div>
<!--        <v-date-picker v-model="end_at_edit" mode="dateTime" :minute-increment="15">-->
<!--          <template v-slot="{ inputValue, inputEvents }">-->
<!--            <input-->
<!--                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"-->
<!--                :value="inputValue"-->
<!--                v-on="inputEvents"-->
<!--            />-->
<!--          </template>-->
<!--        </v-date-picker>-->
      </div>


      <div v-else>
        <input
            :type="type"
            class="form-control"
            :value="modelValue"
            :name="name"
            :disabled="disabled"
            :placeholder="placeholder"
            :class="isError"
            @input="$emit('update:modelValue', $event.target.value)"
        />
      </div>


    </div>
</template>

<script>
// import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import {formatDate} from "../ResusableFunctions/formatDate";


export default {
  // components: {'v-date-picker': DatePicker,},
  props: {
    label: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    isError: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
    },
    value: {
      type: String,
      default: undefined,
    },
    mainLabel: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    selectedValue: {
      type: String,
      required: false,
    },
    startDate:{
      required: false,
    },
    endDate: {
      required: false,
    },
    editMode: {
      required: false,
    },
    startDateEdit:{
      required: false,
    },
    endDateEdit: {
      required: false,
    },
    disabled: {
      required: false,
    }

  },
  computed: {
    start_at: {
      get() {
        return formatDate(this.startDate);
      },
      set(value) {
        this.$emit('startAt', value);
      }
    },
    end_at: {
      get() {
        return formatDate(this.endDate);
      },
      set(value) {
        this.$emit('endAt', value);
      }
    },
    start_at_edit: {
      get() {
        return formatDate(this.startDateEdit);
      },
      set(value) {
        this.$emit("update:modelValue", value)
      }
    },
    end_at_edit: {
      get() {
        return formatDate(this.endDateEdit);
      },
      set(value) {
        this.$emit("update:modelValue", value)
      }
    }
  },
  watch: {
    modelValue() {
      this.adjustHeight();
    }
  },
  mounted() {
    this.adjustHeight();
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
      this.adjustHeight();
    },
    adjustHeight() {
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = 'auto'; // Reset the height to auto to calculate the scrollHeight properly
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to scrollHeight
      }
    }
  },
};
</script>

<style scoped>
.dp__overlay_row{
  height: 100px;
}
</style>