<template>
  <div v-if="errorMessage">
    <base-dialog v-for="message in errorMessage" :key="message" :show="errorMessage.length > 0" :title="message.title" @close = "handleErrorMessage">{{message.message}}</base-dialog>
  </div>
  <base-header></base-header>
  <router-view :key="$route.fullPath"></router-view>
  <base-footer></base-footer>
</template>

<script>
import BaseHeader from "./components/BaseComponents/BaseHeader";
import BaseFooter from "./components/BaseComponents/BaseFooter";

export default {
  components: {BaseHeader, BaseFooter},
  created() {
    this.$store.dispatch('auth/tryLogin');
  },
  computed: {
    errorMessage(){
      return this.$store.getters['auth/getErrorMessage'];
    },
    didAutoLogout(){
      return this.$store.getters['auth/didAutoLogout'];
    }
  },
  methods: {
    async handleErrorMessage(){
      await this.$store.commit('auth/setErrorMessages', null);
    }
  },
  watch: {
    didAutoLogout(curValue, oldValue){
      if(curValue && curValue !== oldValue){
        console.log('AUTO LOGGED OUT');
        this.$router.replace('/login');
      }
    }
  }

}
</script>

<style lang="scss">
@import "./assets/css/style.scss";
</style>

