export default {
    getServices(state){
        return state.services;
    },
    getClients(state){
        return state.clients;
    },
    getSelectedService(state){
        return state.selectedService;
    },
    getPatients(state){
        return state.patients;
    },
    getScheduleAttemptCustomField(state) {
        return state.scheduleAttemptCustomFields;
    }


}