import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return{
            events: [],
            timezone: [
                { value: "America/Adak",  label:  "Adak"},
                { value: "America/Anchorage",  label:  "Anchorage"},
                { value: "America/Boise",  label:  "Boise"},
                { value: "America/Chicago",  label:  "Chicago"},
                { value: "America/Denver",  label:  "Denver"},
                { value: "America/Detroit",  label:  "Detroit"},
                { value: "America/Indiana/Indianapolis",  label:  "Indianapolis"},
                { value: "America/Indiana/Knox",  label:  "Knox"},
                { value: "America/Indiana/Marengo",  label:  "Marengo"},
                { value: "America/Indiana/Petersburg",  label:  "Petersburg"},
                { value: "America/Indiana/Tell_City",  label:  "Tell City"},
                { value: "America/Indiana/Vevay",  label:  "Vevay"},
                { value: "America/Indiana/Vincennes",  label:  "Vincennes"},
                { value: "America/Indiana/Winamac",  label:  "Winamac"},
                { value: "America/Kentucky/Louisville",  label:  "Louisville"},
                { value: "America/Kentucky/Monticello",  label:  "Monticello"},
                { value: "America/Los_Angeles",  label:  "Los Angeles"},
                { value: "America/New_York",  label:  "New York"},
                { value: "America/Nome",  label:  "Nome"},
                { value: "America/North_Dakota/Beulah",  label:  "Beulah"},
                { value: "America/North_Dakota/Center",  label:  "Center"},
                { value: "America/North_Dakota/New_Salem",  label:  "New Salem"},
                { value: "America/Phoenix",  label:  "Phoenix"},
                { value: "America/Sitka",  label:  "Sitka"},
                { value: "America/Yakutat",  label:  "Yakutat"},
                { value: "Europe/London",  label:  "London"},
                { value: "Pacific/Honolulu",  label:  "Hanolulu" },
                { value: "Pacific/Guam",  label:  "Guam" }
            ],
            availableClinicians: [],
            selectedClinician: [],
            addEventForm: [],
            editEventForm: [],
            messages: [],
            noClinicianAvailableMessage: [],
            currentTimezone: [],
            timezoneJson: [
                {
                    "timezone": "America/Adak",
                    "country": "US",
                    "pin": "5,53",
                    "offset": -10,
                    "points": "7,53,6,53,8,52,7,53",
                    "zonename": "HST",
                    "class" : "",
                },
                {
                    "timezone": "America/Anchorage",
                    "country": "US",
                    "pin": "42,40",
                    "offset": -9,
                    "points": "42,42,40,43,43,40,42,39,42,40,41,42,40,42,40,40,36,44,30,47,30,47,25,48,25,48,27,48,27,47,31,45,31,44,30,43,29,44,27,43,27,36,26,36,27,35,25,35,25,33,25,33,25,32,26,33,25,27,33,26,34,26,38,27,39,27,51,45,43,41,44,41,44,41,44,41,42,42",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "America/Boise",
                    "country": "US",
                    "pin": "89,64",
                    "offset": -7,
                    "points": "96,66,96,67,87,67,87,66,86,66,86,64,87,63,88,62,88,61,89,62,92,62,93,63,96,63,96,66",
                    "zonename": "MST",
                    "class" : "",
                },
                {
                    "timezone": "America/Chicago",
                    "country": "US",
                    "pin": "128,67",
                    "offset": -6,
                    "points": "128,72,132,74,132,75,131,76,132,80,132,84,130,83,130,83,129,83,129,82,128,83,128,82,128,83,124,83,126,83,125,84,126,84,126,85,125,84,125,85,120,83,118,84,118,84,118,85,116,86,117,85,116,85,116,86,115,86,115,87,114,87,115,87,115,89,112,88,112,87,109,84,107,84,107,85,105,84,104,82,104,81,107,81,107,74,108,74,108,73,109,73,109,70,108,70,108,69,109,69,109,67,110,67,109,64,111,63,110,63,111,62,111,61,109,61,110,60,110,59,106,59,105,57,118,57,118,56,119,57,126,58,122,60,124,60,124,60,128,61,128,62,128,63,129,63,128,66,129,67,130,67,128,68,128,72",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/Denver",
                    "country": "US",
                    "pin": "104,70",
                    "offset": -7,
                    "points": "93,63,92,62,91,62,91,60,89,59,89,57,105,57,106,59,108,59,108,60,110,61,109,61,111,61,111,62,110,63,111,63,109,64,110,67,109,67,109,69,108,69,108,70,109,70,109,73,108,73,108,74,107,74,107,81,104,81,104,82,102,81,99,81,99,76,96,76,95,74,95,74,92,74,92,67,96,67,96,63,93,63",
                    "zonename": "MST",
                    "class" : "",
                },
                {
                    "timezone": "America/Detroit",
                    "country": "US",
                    "pin": "135,66",
                    "offset": -5,
                    "points": "132,67,129,67,130,66,130,64,130,63,131,62,132,63,132,61,134,62,134,62,134,63,133,65,135,64,136,65,134,67,132,67",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Detroit",
                    "country": "US",
                    "pin": "135,66",
                    "offset": -5,
                    "points": "125,60,127,59,127,60,130,61,132,60,134,61,131,61,130,62,129,61,128,62,129,62,128,61,126,61,125,60",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Indianapolis",
                    "country": "US",
                    "pin": "130,70",
                    "offset": -5,
                    "points": "132,71,130,72,130,71,128,71,128,68,130,68,130,67,132,67,132,70,132,71",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Knox",
                    "country": "US",
                    "pin": "130,68",
                    "offset": -6,
                    "points": "130,67,129,68,130,67",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Marengo",
                    "country": "US",
                    "pin": "130,72",
                    "offset": -5,
                    "points": "130,72,130,72,130,72,130,72",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Petersburg",
                    "country": "US",
                    "pin": "129,72",
                    "offset": -5,
                    "points": "129,72,129,72,129,72",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Tell_City",
                    "country": "US",
                    "pin": "129,72",
                    "offset": -6,
                    "points": "130,72,130,72,130,72",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Vevay",
                    "country": "US",
                    "pin": "132,71",
                    "offset": -5,
                    "points": "132,71,132,71,132,71",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Vincennes",
                    "country": "US",
                    "pin": "128,71",
                    "offset": -5,
                    "points": "128,72,128,71,130,71,130,72,128,72",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Indiana/Winamac",
                    "country": "US",
                    "pin": "130,68",
                    "offset": -5,
                    "points": "130,68,129,68,130,68",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Kentucky/Louisville",
                    "country": "US",
                    "pin": "131,72",
                    "offset": -5,
                    "points": "130,72,130,72,131,71,130,72",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Kentucky/Monticello",
                    "country": "US",
                    "pin": "132,74",
                    "offset": -5,
                    "points": "132,74,133,74,132,74,132,74",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Los_Angeles",
                    "country": "US",
                    "pin": "86,78",
                    "offset": -8,
                    "points": "91,62,88,62,88,61,88,62,87,63,86,64,86,66,87,66,87,67,92,67,92,75,91,75,91,77,91,79,91,79,87,80,85,78,82,77,82,76,80,73,80,72,79,72,78,71,77,69,78,67,77,66,78,61,78,61,78,61,78,60,78,60,78,60,78,60,77,58,80,58,79,59,80,58,80,59,79,60,80,59,80,57,89,57,89,59,91,60,91,62",
                    "zonename": "PST",
                    "class" : "",
                },
                {
                    "timezone": "America/New_York",
                    "country": "US",
                    "pin": "147,68",
                    "offset": -5,
                    "points": "132,82,131,76,132,75,133,74,130,72,132,71,132,71,132,67,136,67,140,66,140,65,144,65,144,64,146,63,152,62,154,59,156,60,156,62,157,63,155,64,154,63,154,64,152,64,151,66,152,67,153,67,153,67,151,67,151,67,151,68,148,68,146,71,145,70,146,72,145,73,145,72,144,71,145,70,144,70,144,72,143,72,144,72,143,72,144,74,143,73,144,74,145,75,144,74,145,75,143,75,145,76,143,76,144,76,143,76,144,76,144,77,138,80,137,82,139,88,138,90,137,90,136,88,136,88,136,88,135,87,136,86,135,86,135,84,133,83,131,84,132,82",
                    "zonename": "EST",
                    "class" : "",
                },
                {
                    "timezone": "America/Nome",
                    "country": "US",
                    "pin": "20,35",
                    "offset": -9,
                    "points": "25,32,23,32,22,31,18,30,19,29,23,29,25,27,25,32",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "America/Nome",
                    "country": "US",
                    "pin": "20,35",
                    "offset": -9,
                    "points": "25,35,24,36,19,35,18,35,19,34,16,34,23,33,22,33,25,33,25,35",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "America/Nome",
                    "country": "US",
                    "pin": "20,35",
                    "offset": -9,
                    "points": "25,42,22,42,20,41,21,40,19,39,22,37,23,37,25,37,25,42",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "America/North_Dakota/Beulah",
                    "country": "US",
                    "pin": "109,59",
                    "offset": -6,
                    "points": "109,59,108,60,108,59,109,59",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/North_Dakota/Center",
                    "country": "US",
                    "pin": "109,60",
                    "offset": -6,
                    "points": "110,60,109,60,110,60",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/North_Dakota/New_Salem",
                    "country": "US",
                    "pin": "109,60",
                    "offset": -6,
                    "points": "110,60,110,60,110,61,108,60,110,60",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/Phoenix",
                    "country": "US",
                    "pin": "94,79",
                    "offset": -7,
                    "points": "99,80,99,81,96,81,91,80,91,77,91,75,91,75,92,74,95,74,95,74,96,76,99,76,99,80",
                    "zonename": "MST",
                    "class" : "",
                },
                {
                    "timezone": "America/Sitka",
                    "country": "US",
                    "pin": "62,46",
                    "offset": -9,
                    "points": "66,45,67,46,65,46,66,45,66,45",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "America/Yakutat",
                    "country": "US",
                    "pin": "56,42",
                    "offset": -9,
                    "points": "56,42,56,42,56,42",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "Pacific/Honolulu",
                    "country": "US",
                    "pin": "31,95",
                    "offset": -10,
                    "points": "59,43,62,42,66,45,65,46,65,45,64,45,65,45,64,45,65,45,64,44,64,44,63,44,62,42,62,42,62,44,61,44,61,43,61,43,61,43,60,43,61,45,80,64,78,63,79,43",
                    "zonename": "HST",
                    "class" : "",
                },
                {
                    "timezone": "America/Juneau",
                    "country": "US",
                    "pin": "63,44",
                    "offset": -9,
                    "points": "59,43,62,42,66,45,65,46,65,45,64,45,65,45,64,45,65,45,64,44,64,44,63,44,62,42,62,42,62,44,61,44,61,43,61,43,61,43,60,43,61,44,60,44,58,43,59,43",
                    "zonename": "AKST",
                    "class" : "",
                },
                {
                    "timezone": "America/Menominee",
                    "country": "US",
                    "pin": "128,62",
                    "offset": -6,
                    "points": "128,62,128,61,124,60,128,61,129,62,128,62",
                    "zonename": "CST",
                    "class" : "",
                },
                {
                    "timezone": "America/Metlakatla",
                    "country": "US",
                    "pin": "67,48",
                    "offset": -8,
                    "points": "67,48,67,48,67,48",
                    "zonename": "PST",
                    "class" : "",
                },
            ],
            patientInfo: [],
            appointmentSearchResult: [],
            addressToTimeZoneResponse: [],
            productScript: [],
            soonestAvailableTimes: [],
            schedulingConflict: [],
            createdApptData: [],
            setupForm: [],
        }
    },
    mutations,
    actions,
    getters,
}