<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" :class="mode">
      <header>
        <slot name="header">
          <h2 v-if="!mode" >{{ title }}</h2>
          <h2 style="color: black" v-if="mode">{{title}}</h2>
        </slot>
      </header>
      <section style="text-align: center">
        <slot></slot>
      </section>
      <menu v-if="!fixed">
        <slot name="actions">
          <base-button mode="cancel" @click="tryClose">Cancel</base-button>
        </slot>
      </menu>
    </dialog>
  </teleport>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      required: false,
      default: false,
    }
  },
  emits: ['close'],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 200vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 11;
}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  background-color: #212529;
  font-size: 140%;
  color: white;
  width: 30rem;
}

header {
  color: white;
  width: 100%;
  padding: 0.5rem;
}

header h2 {
  margin: 0;
  font-size: x-large;
  text-align: center;
}

section {
  padding: 1rem;
}

menu {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}


@media only screen and (min-width:200px) and (max-width: 599px){
  dialog{
    left: calc(50%);
    width: 20rem;
  }
}


#close-dialog{
  font-size: large;
}
</style>