import {fetchData} from "../use-fetch.js";


export default {
    async loadReports(context) {
        const responseData = await fetchData(`/v1/dashboard/report`, {});

        responseData.reports && context.commit('setReports', responseData.reports);
    },

    async loadClientSchedulerReport(context, payload) {
        let startDate = payload.startDate;
        let endDate = payload.endDate;
        let categoryId = payload.categoryId;

        const responseData = await fetchData(`/v1/report/clientAppointment/${categoryId}?start=${startDate}&end=${endDate}`, {});

        responseData.reportData && context.commit('setClientSchedulerReportData', responseData.reportData);
    },

    async loadClickUpTaskUploadsReport(context) {
        const responseData = await fetchData(`v1/report/clickUpTaskUploads`);

        responseData.clickUpTaskUploads && context.commit('setClickUpTaskUploadsReportData', responseData.clickUpTaskUploads);
    },


    async tryUploadClickUpTaskUpload(context, payload) {
        const responseData = await fetchData(`v1/report/clickUpTaskUploads/${payload.taskUploadId}/uploadToClickUp`, {
            method: 'PUT',
        });
        responseData.clickUpTaskUploads && context.commit('setClickUpTaskUploadsReportData', responseData.clickUpTaskUploads);
    },

    async deleteClickUpTaskUpload(context, payload) {
        await fetchData(`v1/report/clickUpTaskUploads/${payload.taskUploadId}`, {
            method: 'Delete',
        });
    },

    async loadEditClickUpTaskUploadForm(context, payload) {
        const responseData = await fetchData(`v1/report/clickUpTaskUploads/${payload.taskUploadId}/edit`);

        const clickUpTaskUploadEditForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                }
                clickUpTaskUploadEditForm.push(editForm);
            });
        }

        context.commit("setClickUpTaskUploadEditForm", clickUpTaskUploadEditForm);
    },

    async saveClickUpTaskUploadEdit(context, payload) {
        await fetchData(`v1/report/clickUpTaskUploads/${payload.taskUploadId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
    },

    async loadInternalSchedulerReport(context, payload) {
        let startDate = payload.startDate;
        let endDate = payload.endDate;

        const responseData = await fetchData(`/v1/report/internalScheduler?start=${startDate}&end=${endDate}`, {});

        responseData.reportData && context.commit('setInternalSchedulerReportData', responseData.reportData);
    },

    async loadSetupErrorsReport(context, payload) {
        const responseData = await fetchData(`/v1/report/setupError`, {});

        responseData.setupErrors && context.commit('setSetupErrorsData', responseData.setupErrors);
    },

    async uploadSetupFieldsToClickUp(context, payload) {
        await fetchData(`/v1/report/setupError/${payload.setupErrorId}/uploadToClickUp`, {
            method: 'PUT',
        });
    },

    async deleteSetupError(context, payload) {
        await fetchData(`v1/report/setupError/${payload.setupErrorId}`, {
            method: 'Delete',
        });
    },
}
