import {createRouter, createWebHashHistory} from "vue-router";
import store from "./store/index.js";

const LoginPage = () => import("./components/Pages/mainPages/LoginPage");
const DashBoard = () => import("./components/Pages/mainPages/DashBoard");
// const ScheduleDashboard = () => import("./components/Pages/Widgets/ManageSchedule/ScheduleDashboard");
const UsersList = () => import("./components/Pages/Widgets/ManageUsers/UsersList");
const EditUser = () => import("./components/Pages/Widgets/ManageUsers/EditUser");
const CreateUser = () => import( "./components/Pages/Widgets/ManageUsers/CreateUser");
const OrganizationsList = () => import("./components/Pages/Widgets/ManageOrganizations/OrganizationsList");
const EditOrganizations = () => import("./components/Pages/Widgets/ManageOrganizations/EditOrganization");
const CreateOrganization = () => import("./components/Pages/Widgets/ManageOrganizations/CreateOrganization");
const ProductsList = () => import("./components/Pages/Widgets/ManageProducts/ProductsList");
const EditProduct = () => import("./components/Pages/Widgets/ManageProducts/EditProduct");
const CreateProduct = () => import("./components/Pages/Widgets/ManageProducts/CreateProduct");
const PasswordReset = () => import("./components/Pages/mainPages/PasswordReset");
const CreateNewPassword = () => import("./components/Pages/mainPages/PasswordCreate");
const PasswordEmailCheck = () => import("./components/Pages/mainPages/PasswordEmailCheck");
const PasswordSuccess = () => import("./components/Pages/mainPages/PasswordSuccessfullyReset");
const Calendar = () => import("./components/Pages/Widgets/ManageSchedule/CalendarView");
const CategoriesList = () => import("./components/Pages/Widgets/ManageProductCategories/CategoriesList");
const EditCategory= () => import("./components/Pages/Widgets/ManageProductCategories/EditCategory");
const CreateCategory = () => import("./components/Pages/Widgets/ManageProductCategories/CreateCategory");
const ThirdPartyDashboard = () => import("./components/Pages/ThirdPartySchedulerPages/Dashboard");
const ThirdPartySchedule= () => import("./components/Pages/ThirdPartySchedulerPages/Schedule");
const ThirdPartyScheduleSuccess= () => import("./components/Pages/ThirdPartySchedulerPages/ScheduleSuccess");
const ClinicianCalendar = () => import("./components/Pages/Widgets/ManageSchedule/ClinicianCalendar");
const PatientCancelAppointment = () => import("./components/Pages/ThirdPartySchedulerPages/PatientCancelAppointment");
const PatientCancelAppointmentSuccessful = () => import("./components/Pages/ThirdPartySchedulerPages/PatientCancelAppointmentSuccessful");
const InServiceDashboard = () => import("./components/Pages/InServiceAppointmentPages/Dashboard");
const InServiceSchedule= () => import("./components/Pages/InServiceAppointmentPages/Schedule");
const InServiceScheduleSuccess= () => import("./components/Pages/InServiceAppointmentPages/ScheduleSuccess");
const NotFound = () => import("./components/Pages/mainPages/NotFound");
const ReportsDashboard = () => import("./components/Pages/Reports/ReportsDashboard");
const ClientSchedulerReport = () => import("./components/Pages/Reports/ClientSchedulerReport");
const ClickUpTaskUploadsReport = () =>  import("./components/Pages/Reports/ClickUpTaskUploadsReport.vue");
const InternalSchedulerReport = () =>  import("./components/Pages/Reports/InternalSchedulerReport");
const SetupErrorReport = () =>  import("./components/Pages/Reports/SetupErrorReport.vue");





const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{ path: '/', redirect: '/dashboard'},
		{ path: '/login', component: LoginPage, meta: { requiresAuth: false}},
		{ path: '/dashboard', component: DashBoard, props: true, meta: { requiresAuth: true}},
		{ path: '/dashboard/schedule/:id', component: Calendar, props: true, meta: { requiresAuth: true}},
		// { path: '/dashboard/schedule/:id/closestAvailable', component: ClosestAvailableAppointment, props: true, meta: { requiresAuth: true}},
		{ path: '/users', component: UsersList, meta: { requiresAuth: true}},
		{ path: '/users/:id', component: EditUser, props: true, meta: { requiresAuth: true}},
		{ path: '/createUser', component: CreateUser, meta: {requiresAuth: true}},
		{ path: '/organizations', component: OrganizationsList, meta: {requiresAuth: true}},
		{ path: '/organizations/:id', component: EditOrganizations, props: true, meta: { requiresAuth: true}},
		{ path: '/createOrganizations', component: CreateOrganization, meta: {requiresAuth: true}},
		{ path: '/products', component: ProductsList, meta: {requiresAuth: true}},
		{ path: '/products/:id', component: EditProduct, props: true, meta: { requiresAuth: true}},
		{ path: '/createProduct', component: CreateProduct, meta: {requiresAuth: true}},
		{ path: '/resetPassword', component: PasswordReset, meta: { requiresAuth: false}},
		{ path: '/createPassword/:token', component: CreateNewPassword, props: true, meta: { requiresAuth: false}},
		{ path: '/checkEmail', component: PasswordEmailCheck, meta: { requiresAuth: false}},
		{ path: '/passwordSuccess', component: PasswordSuccess, meta: { requiresAuth: false}},
		{ path: '/categories', component: CategoriesList, meta: { requiresAuth: true}},
		{ path: '/categories/:id', component: EditCategory, props: true, meta: { requiresAuth: true}},
		{ path: '/createCategory', component: CreateCategory, meta: {requiresAuth: true}},
		{ path: '/liaison/schedule/:slug', component: ThirdPartyDashboard, props: true, meta: {requiresAuth: false}},
		{ path: '/liaison/schedule/:slug/:productId', component: ThirdPartySchedule, props:true, meta: {requiresAuth: false}},
		{ path: '/liaison/schedule/:slug/:productId/success', component: ThirdPartyScheduleSuccess, props:true, meta: {requiresAuth: false}},
		{ path: '/inservice/schedule/:slug', component: InServiceDashboard, props: true, meta: {requiresAuth: false}},
		{ path: '/inservice/schedule/:slug/:productId', component: InServiceSchedule, props:true, meta: {requiresAuth: false}},
		{ path: '/inservice/schedule/:slug/:productId/success', component: InServiceScheduleSuccess, props:true, meta: {requiresAuth: false}},
		{ path: '/dashboard/clinician/:id', component: ClinicianCalendar, props: true, meta: { requiresAuth: true}},
		{ path: '/cancelAppointment/:id', component: PatientCancelAppointment, props:true, meta: {requiresAuth: false}},
		{ path: '/cancelAppointment/:id/success', component: PatientCancelAppointmentSuccessful, props:true, meta: {requiresAuth: false}},
		{ path: '/reports', component: ReportsDashboard, props:true, meta: {requiresAuth: true}},
		{ path: '/reports/clientSchedulerReport', component: ClientSchedulerReport , props: true, meta: { requiresAuth: true}},
		{ path: '/reports/clickUpTaskUploadsReport', component: ClickUpTaskUploadsReport , props: true, meta: { requiresAuth: true}},
		{ path: '/reports/internalSchedulerReport', component: InternalSchedulerReport, props: true, meta: { requiresAuth: true}},
		{ path: '/reports/setupErrorReport', component: SetupErrorReport, props: true, meta: { requiresAuth: true}},
		{ path: '/:notFound(.*)', component: NotFound, meta: {requiresAuth: false}},
	]
});

router.beforeEach(async function (to, from, next) {
	if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
		next('/login');
	} else if(to.meta.requiresAuth && store.getters['auth/isAuthenticated']) {
		next();
	} else if(to.path === '/login' && store.getters['auth/isAuthenticated']) {
		next('/dashboard');
	} else if(to.meta.requiresAuth === false) {
		next();
	}

});

export default router;