export default {
    getProductCategories(state) {
        return state.categories;
    },
    hasCategories(state) {
        return state.categories && state.categories.length > 0;
    },
    getCreateCategoryForm(state) {
        return state.createCategoryForm;
    },
    getEditCategoryForm(state) {
        return state.editCategoryForm;
    }
}