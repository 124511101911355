import {fetchData} from "../use-fetch.js"

export default {
    async loadOrganizations(context) {
        const responseData = await fetchData('v1/organization', {});

        const organizations = [];

        if(responseData.organizations) {
            responseData.organizations.forEach(function (orgs) {
                const activeOrgs = {
                    name: orgs.name,
                    domain: orgs.domain,
                    id: orgs.token,
                }
                organizations.push(activeOrgs);
            });
        }

        context.commit("setActiveOrganizations", organizations);
    },

    async loadEditOrganizationForm(context, payload) {
        const orgId = payload.orgId

        const responseData = await fetchData(`/v1/organization/${orgId}/edit`, {});

        const editOrganizationForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                }
                editOrganizationForm.push(editForm);

            });
        }

        context.commit("editOrganizationForm", editOrganizationForm);
    },

    async saveEditOrganizationForm(context, payload) {
        const orgId = payload.orgId
        await fetchData(`/v1/organization/${orgId}`, {
            method: 'PUT',
            body: JSON.stringify(payload.formData),
        });
    },

    async createOrganizationForm(context) {
        const responseData = await fetchData('/v1/organization/new');

        const createOrganizationForm = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const createForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                }
                createOrganizationForm.push(createForm);
            });
        }

        context.commit("createOrganizationForm", createOrganizationForm);
    },
    async saveCreateOrganizationForm(context, payload) {
        await fetchData('v1/organization', {
            method: 'POST',
            body: JSON.stringify(payload.formData),
        });
    },

    async deleteOrganization(context, payload) {
        const orgId = payload.orgId
        await fetchData(`/v1/organization/${orgId}`, {
            method: 'Delete',
        });
    },

    async changeActiveOrg(context, payload) {
        const orgId = payload.orgId
        await fetchData(`v1/organization/${orgId}/setActive`, {
            method: 'PUT',
            body: JSON.stringify(payload.formData),
        });
    },


}