export default {
    events(state){
        return state.events;
    },
    timezone(state){
        return state.timezone;
    },
    availableClinicians(state){
        return state.availableClinicians;
    },
    selectedClinician(state){
        return state.selectedClinician + ' RRT';
    },
    getAddEventForm(state){
        return state.addEventForm;
    },
    getEditEventForm(state){
        return state.editEventForm;
    },
    getMessages(state){
        return state.messages;
    },
    getCurrentTimezone(state){
        return state.currentTimezone;
    },
    getTimezoneMapData(state){
        return state.timezoneJson;
    },
    getPatientInfo(state){
        return state.patientInfo;
    },
    getAppointmentSearchResult(state){
        return state.appointmentSearchResult;
    },
    getProductScript(state){
        return state.productScript;
    },
    getAddressToTimeZoneResponse(state){
        return state.addressToTimeZoneResponse;
    },
    getSoonestAvailableTimes(state) {
        return state.soonestAvailableTimes;
    },
    getSchedulingConflict(state) {
        return state.schedulingConflict;
    },
    getCreatedApptData(state) {
        return state.createdApptData;
    },
    getSetupForm(state) {
        return state.setupForm;
    }
}