import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions";
export default {
	namespaced: true,
	state() {
		return {
			users: [],
			userEditForm: [],
			createUserForm: [],
			userAvailability: [],
			editUserAvailability: [],
			productOfUsers : [],
			userLicense: [],
			userRole: [],
		}
	},
	mutations,
	actions,
	getters,
}